import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../store/userSlice";
import { useHistory, useLocation } from "react-router-dom";

import UnSubscribeModal from "../components/Modals/unSubscribeModal";
import dateFormat from "dateformat";
// import { CIRCLECHECKMARK } from "../constants/imagepath";
import AfterLoginHeader from "../components/header/AfterLoginHeader";
import Footer from "../components/footer/Footer";
import {
  getContent
} from "../api_services/api_calls/contentPageApi";
import axios from "axios";
import { getJobs, postJob, searchJob, filterJobs, getJob, getUserJob } from "../api_services/api_calls/jobsApi";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import { useForm } from "react-hook-form";
import {
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { JobModal } from "./Modals/JobModal";
import InfiniteScroll from "react-infinite-scroll-component";
import JobFilterTabs from "./job-components/JobFilterTabs";
import AddJob from "./job-components/AddJob";

function useQuery() {
  // "help in parsing and accessing query parameters"
  return new URLSearchParams(useLocation().search);
}

export default function Job(props) {
  const { userData } = useSelector(userSelector);
  let query = useQuery(); // "We will use useQuery function here"
  let query_param_id = query.get("myjob"); // "Variable for query param for get id"
  const {
    register,
    trigger,
    setError,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm(); // using react useform hook values

  // const [packages, setPackages] = useState([]);
  const [postType, setPostType] = useState("Job Wanted");
  const [filterVal, setFilterVal] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [nextBillingDate, setBillingDate] = useState();
  const [currentPackagePrice, setCurrentPackagePrice] = useState([]);
  const [packageName, setPackageName] = useState([]);
  const [packageStatus, setPackageStatus] = useState();
  // const [packageActive, setPackageActive] = useState();
  const [isSubscribed, setIsSubscribed] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [searchData, setSearchData] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const inputref = useRef(null);



  const toggle = (tooltipId) => {
    setTooltipOpen(tooltipOpen === tooltipId ? null : tooltipId);
  };
  console.log('tooltipOpen', tooltipOpen)
  /******* Calling Dynamic URL ********/
  // const apiUrl = process.env.REACT_APP_API_KEY;

  const handleToggle = (packageId) => {
    // handle to manage pop up for particular id
    setConfirmModal(true);
    setSelectedPlan(packageId);
  };
  // const [loader, setLoader] = useState(false);
  // Fetch data based on the slug, or use a static mapping
  const [pageTitle, setPageTitle] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [jobsCount, setJobsCount] = useState(1);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobDetail, setJobDetail] = useState([]);

  const apiUrl = process.env.REACT_APP_API_KEY;

  function onTabChange(value) {
    // handle for set tab of jobs
    console.log('val', value)
    setLoader(true);
    setHasMoreItems(true);
    setPage(1); // page = 1

    const selectedVal = value;
    if (selectedVal !== '') {
      setPostType(selectedVal);
    }
    filterJobCall(selectedVal, filterVal, 1 /*page number*/);
  }

  function handleChange(event) {
    // handle for set filter of jobs
    setLoader(true);
    setHasMoreItems(true);
    setPage(1); // page = 1

    const selectedVal = event.target.value;
    const selectedPostType = postType ? postType : 'Job Wanted';
    console.log('postType', postType)
    if (selectedVal !== '') {
      setFilterVal(selectedVal);
      setPostType(selectedPostType)
    }
    filterJobCall(selectedPostType, selectedVal, 1);


  }


  const fetchData = async () => {
    console.log('fetchData', fetchData)
    // "Function for fetch data for search filters and this function will trigered when user scroll the product page"
    setPage(page + 1);
    var pageNew;
    pageNew = page + 1;
    console.log('page', pageNew)

    const obj = { job_offered_or_job_wanted: postType, marine_or_fishing: filterVal }
    if (!query_param_id) {
      await filterJobs(obj, pageNew).then(function (resp) {
        //setHasMoreItems(false);
        const jobsResponse = resp.data.jobslist.data;
        const respCount = Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length;
        setJobs([...jobs, ...Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse)]);
        setJobsCount(respCount);
        var itemsCountNew = itemsCount + respCount;
        setItemsCount(itemsCountNew);
        if (respCount > 0) {
          setHasMoreItems(true);
          // setCardDataAppend(true);
        } else {
          setHasMoreItems(false);
          // setCardDataAppend(false);
          setPage(1);
        }
        setSearchData(false)
      }).catch(function (error) {
        setLoader(false);
      });
    }
    else {
      await getUserJob(obj, pageNew).then(function (resp) {
        //setHasMoreItems(false);
        const jobsResponse = resp.data.jobslist.data;
        const respCount = Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length;
        setJobs([...jobs, ...Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse)]);
        setJobsCount(respCount);
        var itemsCountNew = itemsCount + respCount;
        setItemsCount(itemsCountNew);
        if (respCount > 0) {
          setHasMoreItems(true);
          // setCardDataAppend(true);
        } else {
          setHasMoreItems(false);
          // setCardDataAppend(false);
          setPage(1);
        }
        setSearchData(false)
      }).catch(function (error) {
        setLoader(false);
      });
    }

  };


  useEffect(() => { // function for get the jobs after loading or refreshing thepage
    const page = 1;
    console.log('useEffectCalling')
    console.log('query_params', query_param_id)
    setLoader(true);
    const obj = { job_offered_or_job_wanted: postType, marine_or_fishing: filterVal };
    // if (filterType !== 'All') {
    //   obj.posting_type = filterType;
    // }
    if (!query_param_id) {
      filterJobs(obj, page) // api call for get the jobs via api services
        .then(function (response2) {
          setLoader(false);
          var jobsResponse = response2.data.jobslist.data;
          console.log('response1', jobsResponse);
          const jobsData = Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse);
          setJobsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
          setJobs(jobsData);
          setItemsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
        })
        .catch(function (error) {
          setLoader(false);
        });
    } else {
      const obj = { job_offered_or_job_wanted: postType, marine_or_fishing: filterVal }
      console.log('obj', obj);
      getUserJob(obj, page).then(function (response2) {
        setLoader(false);
        console.log('response2', response2)
        var jobsResponse = response2.data.jobslist;
        const jobsData = Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse);
        setJobsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
        setJobs(jobsData);
        setItemsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
      })
        .catch(function (error) {
          setLoader(false);
        });
    }
    // eslint-disable-next-line
  }, [query_param_id]);

  const filterJobsByuser = (data) => {
    console.log('response_data', data)
    return data.filter(record => record.user_id === userData.user.id);

  }
  const filterJobCall = (job_type, filter_val, pageNo) => {
    let currentPage = pageNo ?? page;
    const obj = { job_offered_or_job_wanted: job_type, marine_or_fishing: filter_val }
    if (!query_param_id) {
      filterJobs(obj, currentPage).then(function (resp) {
        setLoader(false);
        const jobsResponse = resp.data.jobslist.data;
        setJobs(Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse));
        setJobsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
        setSearchData(false)
        setSearchParam("");
        inputref.current.value = "";
      }).catch(function (error) {
        setLoader(false);
      });
    }
    else {
      console.log('object', obj)
      getUserJob(obj, currentPage).then(function (response2) {
        setLoader(false);
        console.log('response2', response2)
        var jobsResponse = response2.data.jobslist;
        const jobsData = Array.isArray(jobsResponse) ? jobsResponse : Object.values(jobsResponse);
        setJobsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
        setJobs(jobsData);
        setItemsCount(Array.isArray(jobsResponse) ? jobsResponse.length : Object.keys(jobsResponse).length);
      })
        .catch(function (error) {
          setLoader(false);
        });
    }

  }

  const calculateTimeDifference = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Get the current date
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - date;

    // Convert milliseconds to days
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    // Format the result
    return `${diffInDays}d ago`;
  };
  console.log('userdetails', userData)
  const onSubmit = async (data) => {
    // "this function is using for add the new job submit"
    console.log('submit form')
    console.log('jobTitle', data.jobTitle)
    setSubmitting(true)
    const user_name = userData.data.profile.profile_data?.first_name + ' ' + userData.data.profile.profile_data?.middle_name + ' ' + userData.data.profile.profile_data?.last_name;
    let obj = {
      user_id: userData.data.user.id,
      name: data.userName,
      email: data.userEmail,
      phone_no: data.userPhone,
      title: data.jobTitle,
      job_type: data.jobType,
      posting_type: data.postingType,
      preferred_location: data.jobLocation,
      description: data.jobDescription,
      work_location: data.workLocation,
      job_duration: data.jobDuration,
      salary: data.jobSalary,
      certifications: data.certifications,
      additional_information: data.additionalInformation,
    };
    console.log("obj", obj);

    postJob(obj) // function for update seller request listing api call via api services
      .then(function (response2) {
        setSubmitting(false)
        reset();
        toast.success(response2.data.message);
      })
      .catch(function () {
        toast.error("Something went wrong");
      });

  };

  // Handle search logic
  const handleSearch = async () => {
    setLoader(true);
    let searchObj = { job_offered_or_job_wanted: postType, marine_or_fishing: filterVal, job_title: searchParam }
    searchJob(searchObj) // function for update seller request listing api call via api services
      .then(function (response2) {
        setLoader(false);
        var jobsResponse = response2.data;
        setJobsCount(Object.keys(jobsResponse.jobslist).length);
        setJobs(jobsResponse.jobslist);
        setSearchData(true);
      })
      .catch(function () {
        toast.error("Something went wrong");
      });

  };

  // Handle Input Change
  const handleInputChange = (event) => {
    setSearchParam(event.target.value);
  };
  // Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      handleSearch();
    }
  };

  const handleOpenModal = (id) => {
    console.log('selectedId', id)
    setJobDetail([]);
    const obj = { job_post_id: id, user_id: userData.data.user.id };
    getJob(obj)
      .then(function (response2) {
        setLoader(false)
        var listingResponse = response2.data;
        setJobDetail(listingResponse.jobDetails);

      })
      .catch(function (error) {
        console.log(error);
      });
    //setJobDetail(selectedJob)
    setIsModalOpen(true);
  };


  // Function to get job details by ID
  const getJobById = (id) => {
    return jobs.find(job => job.id === id);
  };

  const resetFilter = () => {
    setLoader(true);
    setPage(1);
    filterJobCall(postType, filterVal);
  }


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  console.log('jobsList', jobs)
  console.log('itemsCount', itemsCount)

  return (
    <>
      <AfterLoginHeader userData={userData} />
      <section className="package_section ct_px_0 pt-5">
        <div className="container">
          <div className="row">
            {/** Wanted/Offered filters with functionality */}
            <JobFilterTabs postType={postType} onChangeTab={onTabChange} />

            <div className="col-md-12 text-end mb-3">
              <div className="d-flex align-items-center gap-2 justify-content-between">
                <div className="ct_job_search">
                  <input type="text" placeholder="Search Job" className="form-control mb-0" onChange={handleInputChange}
                    ref={inputref} onKeyDown={handleKeyDown} />
                  {searchData ?
                    <><span onClick={resetFilter}><i className="fa fa-close" /></span></>
                    :
                    <><span><i className="fa fa-search" /></span>
                    </>
                  }
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <select name="" id="" onChange={handleChange} className="form-control ct_skill_select mb-0">
                      <option value="">All</option>
                      <option value="Marine">Marine</option>
                      <option value="Fishing">Fishing</option>
                    </select>
                  </div>
                  <div>

                    <AddJob
                      submitting={submitting}
                      onSubmit={onSubmit}
                      toggle={toggle}
                      isModalOpen={isModalOpen}
                      handleCloseModal={handleCloseModal}
                      jobDetail={jobDetail}
                      userData={userData}
                    />
                  </div>
                </div>

              </div>
            </div>

          </div>
          {
            loader ?
              <Skeleton count={7.7} />
              :
              <div className="infinite-scroll-section ct_infinite_scroll_hide_on_responsive">
                <InfiniteScroll
                  dataLength={itemsCount}
                  next={fetchData}
                  hasMore={hasMoreItems}
                  loader={
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        bottom: "0",
                        border: "8px solid #ced6d9",
                        borderRadius: "50%",
                        borderTop: "8px solid #004964",
                        width: "50px",
                        height: "50px",
                        webkitAnimation: "spin 2s linear infinite" /* Safari */,
                        animation: "spin 2s linear infinite",
                      }}
                    ></div>
                  }
                  height={500}
                  className={"row ct_dash_scroll_big_screen"}
                  scrollThreshold={0.95}
                  endMessage={
                    <p className="ct_pad_15" style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {
                    jobs.map((job, index) => (
                      <div className="col-lg-6 mb-4" key={index}>
                        <div className="ct_job_card">
                          <h6>{job.posting_type}</h6>
                          <h4>{job.title}</h4>

                          <ul>
                            <li>
                              {/* {calculateTimeDifference(job.creation_time)} */}
                              {dateFormat(job.creation_time, "mmmm dS, yyyy")}
                            </li>
                            <li>
                              {job.job_type}
                            </li>
                            <li>{job.preferred_location}</li>
                          </ul>
                          <div className="d-flex mt-2" >
                            <button className="btn btn-sm btn-outline-primary" onClick={() => handleOpenModal(job.id)}>Read More</button>
                            <div className="text-end" style={{ width: "70%" }}>
                              {
                                job.job_status == 1 ?
                                  <span class="badge badge-success p-2">Open</span> :
                                  <span class="badge badge-warning p-2">Filled</span>
                              }
                            </div>
                          </div>


                        </div>

                      </div>
                    ))
                  }
                </InfiniteScroll>

              </div>
          }
        </div>
      </section>

      <Footer />


    </>
  );
}
