import { Controller } from "react-hook-form";
// import SearchSelect from "../../selectSearch/SearchSelect";
import SearchSelectCreate from "../../selectSearch/SearchSelectCreate";

const Step5 = ({
  handleFormData,
  clearErrors,
  selectValues,
  error,
  setValue,
  getValues,
  control,
}) => {
  console.log("getConstMaterial  reached", getValues("ConstructionMet"));
  return (
    <>
      <p className="good_day_para">
        Please Select The Construction Material
        <span className="required-field"></span>
      </p>
      <div className="row">
        <div className="col-md-12">
          <span className="popup_input_label">Constructions Material</span>
        </div>
      </div>
      <div className="combo-select-dropdown">

        <Controller
          name="ConstructionMet"
          control={control}
          defaultValue=""
          rules={{ required: "This field is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <SearchSelectCreate
              defaultValue={
                getValues("ConstructionMet") !== undefined ||
                  getValues("ConstructionMet") !== null
                  ? selectValues.constructions.find(
                    (e) => e.id === getValues("ConstructionMet")
                  )
                    ? {
                      value: selectValues.constructions.find(
                        (e) => e.id === getValues("ConstructionMet")
                      ).id,
                      label: selectValues.constructions.find(
                        (e) => e.id === getValues("ConstructionMet")
                      )?.name,
                    }
                    : {
                      value: getValues("ConstructionMet"),
                      label: getValues("ConstructionMet"),
                    }
                  : ""
              }
              placeholder='Please select construction material'
              // value={value}
              onChange={(data, action) => {
                setValue("ConstructionMet", data.value);
                handleFormData({
                  target: {
                    value: data.value,
                    name: "ConstructionMet",
                  },
                });
                clearErrors("ConstructionMet");
              }}
              options={selectValues?.constructions?.map((consts, index) => {
                return { value: consts.id, label: consts.name };
              })}
            />
          )}
        />
        {error.ConstructionMet && (
          <span className="err-msg">{error.ConstructionMet.message}</span>
        )}
      </div>
    </>
  );
};

export default Step5;
