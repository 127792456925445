import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import SearchSelect from "../../selectSearch/SearchSelect";
import SearchSelectCreate from "../../selectSearch/SearchSelectCreate";

const LicenseListing = ({
  handleFormData,
  values,
  selectValues,
  register,
  error,
  handleLicenseArea,
  clearErrors,
  getValues,
  trigger,
  setValue,
  control,
}) => {
  const [brokerAreas, setbrokerAreas] = useState([]); //state for store broker area data

  function handleArea(data, fieldType) {
    const name = fieldType;
    // const value = evt.target.value;
    let region;
    let species;

    if (name === "LicenseRegion") {
      region = data.value;
      species = values.LicenseSpecies;
    } else if (name === "LicenseSpecies") {
      species = data.value;
      region = values.LicenseRegion;
    }

    if (region > 0 && species > 0) {
      selectValues.brokerArea = [];
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_API_KEY +
          "get-region-species-area/" +
          region +
          "/" +
          species +
          "/0",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          setbrokerAreas(response.data.data);
          handleLicenseArea(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  return (
    <>
      <p className="good_day_para">
        Please fill the following details for {values.ItemType}
      </p>
      <form>
        <div className="row">
          {/* <div className="col-md-12">
            <span className="popup_input_label">
              License Number<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="LicenseNumber"
              defaultValue={values.LicenseNumber}
              className="popup_input"
              id="LicenseNumber"
              {...register("LicenseNumber", {
                required: "This field is required",
              })}
              // onChange={handleFormData}
              onChange={(e) => {
                clearErrors("LicenseNumber");
                handleFormData(e);
              }}
            />
            {error?.LicenseNumber && (
              <span className="err-msg">{error?.LicenseNumber?.message}</span>
            )}
          </div> */}
          <div className="col-md-12">
            <span className="popup_input_label">
              Species <span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseSpecies"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("LicenseSpecies", selectedOption?.value);
                    handleArea(selectedOption, "LicenseSpecies");
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "LicenseSpecies",
                      },
                    });
                    clearErrors("LicenseSpecies");
                  }}
                  defaultValue={
                    selectValues?.generalSpecies.find(
                      (e) => e.id === getValues("LicenseSpecies")
                    )
                      ? {
                        value: selectValues?.generalSpecies.find(
                          (e) => e.id === getValues("LicenseSpecies")
                        ).id,
                        label: selectValues?.generalSpecies.find(
                          (e) => e.id === getValues("LicenseSpecies")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseSpecies"),
                        label: getValues("LicenseSpecies"),
                      }
                  }
                  options={selectValues.generalSpecies.map((berth, index) => {
                    return { value: berth.id, label: berth.name };
                  })}
                />
              )}
            />

            {/* {error.LicenseSpecies && <span className="err-msg">This field is required</span>} */}

            {error?.LicenseSpecies && (
              <span className="err-msg">{error?.LicenseSpecies?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Region<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseRegion"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("LicenseRegion", selectedOption?.value);
                    handleArea(selectedOption, "LicenseRegion");
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "LicenseRegion",
                      },
                    });
                    clearErrors("LicenseRegion");
                  }}
                  defaultValue={
                    selectValues?.brokerRegion.find(
                      (e) => e.id === getValues("LicenseRegion")
                    )
                      ? {
                        value: selectValues?.brokerRegion.find(
                          (e) => e.id === getValues("LicenseRegion")
                        ).id,
                        label: selectValues?.brokerRegion.find(
                          (e) => e.id === getValues("LicenseRegion")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseRegion"),
                        label: getValues("LicenseRegion"),
                      }
                  }
                  options={selectValues.brokerRegion.map((region, index) => {
                    return { value: region.id, label: region.name };
                  })}
                />
              )}
            />
            {error?.LicenseRegion && (
              <span className="err-msg">{error?.LicenseRegion?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              License Area <span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseArea"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("LicenseArea", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "LicenseArea",
                      },
                    });
                    clearErrors("LicenseArea");
                  }}
                  defaultValue={
                    brokerAreas.find((e) => e.id === getValues("LicenseArea"))
                      ? {
                        value: brokerAreas.find(
                          (e) => e.id === getValues("LicenseArea")
                        ).id,
                        label: brokerAreas.find(
                          (e) => e.id === getValues("LicenseArea")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseArea"),
                        label: getValues("LicenseArea"),
                      }
                  }
                  options={brokerAreas?.map((region, index) => {
                    return { value: region.id, label: region.name };
                  })}
                />
              )}
            />
            {error?.LicenseArea && (
              <span className="err-msg">{error?.LicenseArea?.message}</span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              {" "}
              Province<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            {/* <select className="popup_input" id="province"  {...register("LicenseProvince", { required: "This field is required." })}
                            onChange={(e) => {
                                clearErrors("LicenseProvince");
                                handleFormData(e);
                            }}
                            name="LicenseProvince">
                            <option value="">Choose...</option>
                            {selectValues.states.map((berth, index) => (
                                <option value={berth.id}>{berth.name}</option>
                            ))}
                        </select> */}
            <Controller
              name="LicenseProvince"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("LicenseProvince", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "LicenseProvince",
                      },
                    });
                    clearErrors("LicenseProvince");
                  }}
                  defaultValue={
                    selectValues?.states.find(
                      (e) => e.id === getValues("LicenseProvince")
                    )
                      ? {
                        value: selectValues?.states.find(
                          (e) => e.id === getValues("LicenseProvince")
                        ).id,
                        label: selectValues?.states.find(
                          (e) => e.id === getValues("LicenseProvince")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseProvince"),
                        label: getValues("LicenseProvince"),
                      }
                  }
                  options={selectValues?.states?.map((region, index) => {
                    return { value: region.id, label: region.name };
                  })}
                />
              )}
            />
            {/* {error.LicenseProvince && <span className="err-msg">This field is required</span>} */}
            {error?.LicenseProvince && (
              <span className="err-msg">{error?.LicenseProvince?.message}</span>
            )}
          </div>
          {/* <div className="col-md-12">
            <span className="popup_input_label">
              {" "}
              License Type<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
          
            <Controller
              name="LicenseType"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("LicenseType", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "LicenseType",
                      },
                    });
                    clearErrors("LicenseType");
                  }}
                  defaultValue={
                    selectValues?.brokerLicenseTypeGeneral.find(
                      (e) => e.id === getValues("LicenseType")
                    )
                      ? {
                        value: selectValues?.brokerLicenseTypeGeneral.find(
                          (e) => e.id === getValues("LicenseType")
                        ).id,
                        label: selectValues?.brokerLicenseTypeGeneral.find(
                          (e) => e.id === getValues("LicenseType")
                        )?.name,
                      }
                      : {
                        value: getValues("LicenseType"),
                        label: getValues("LicenseType"),
                      }
                  }
                  options={selectValues?.brokerLicenseTypeGeneral?.map(
                    (region, index) => {
                      return { value: region.id, label: region.name };
                    }
                  )}
                />
              )}
            />
            {error?.LicenseType && (
              <span className="err-msg">{error?.LicenseType?.message}</span>
            )}
          </div> */}
          {/* <div className="col-md-12">
            <span className="popup_input_label">License Or Quota</span>
          </div>
          <div className="col-md-12">
            <select
              className="popup_input"
              id="license_or_quota"
              name="LicenseOrQuota"
              onChange={handleFormData}
            >
              <option value="9" selected>
                License
              </option>
              <option value="8" selected>
                Quota
              </option>
            </select>
          </div> */}

          <div className="col-md-12">
            <span className="popup_input_label">
              Min Price
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MinPriceWillingToPay"
              defaultValue={values.MinPriceWillingToPay}
              className="popup_input"
              id="MinPriceWillingToPay"
              {...register("MinPriceWillingToPay", {
                valueAsNumber: true,
              })}
              onChange={(e) => {
                clearErrors("MinPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MinPriceWillingToPay && (
              <span className="err-msg">
                {error?.MinPriceWillingToPay?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Max Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MaxPriceWillingToPay"
              defaultValue={values.MaxPriceWillingToPay}
              className="popup_input"
              id="ItemPrice"
              {...register("MaxPriceWillingToPay", {
                required: "This field is required.",
                validate: {
                  maxNumber: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Maximum price should not be less than or equal to $100";
                    }
                    trigger("MinPriceWillingToPay");
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MaxPriceWillingToPay");
                handleFormData(e);
              }}
            />
            <span className="err-msg">
              {error?.MaxPriceWillingToPay?.message}
            </span>
          </div>
        </div>
      </form>
    </>
  );
};

const PackageListing = ({
  handleChangeOtherNormal,
  handleCustomButtonSelect,
  handleFormDataOther,
  valuesOther,
  values,
  selectValues,
  clearErrors,
  register,
  error,
  btnError,
  setValue,
  getValues,
  control,
  handleFormData,
  handleButtonInput,
  trigger
}) => {
  console.log('values_packateitem_data', values?.PackageItemType)
  return (
    <>
      {(values?.PackageItemType === "License") ? (
        <LicenseListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          control={control}
          handleFormData={handleFormData}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
        />
      )
        // : (values?.PackageItemType === "Quota") ? (
        //   <QuotaListing
        //     handleChangeOtherNormal={handleChangeOtherNormal}
        //     control={control}
        //     handleFormDataOther={handleFormDataOther}
        //     valuesOther={valuesOther}
        //     values={values}
        //     selectValues={selectValues}
        //     register={register}
        //     error={error}
        //     clearErrors={clearErrors}
        //     setValue={setValue}
        //     getValues={getValues}
        //   />
        // ) 
        : values?.PackageItemType === "Package" ? (
          <PackageListing
            handleCustomButtonSelect={handleCustomButtonSelect}
            handleChangeOtherNormal={handleChangeOtherNormal}
            control={control}
            handleFormData={handleFormData}
            handleFormDataOther={handleFormDataOther}
            valuesOther={valuesOther}
            values={values}
            selectValues={selectValues}
            register={register}
            error={error}
            btnError={btnError}
            clearErrors={clearErrors}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}
          />
        ) : values?.PackageItemType === "Equipment" ? (
          <EquipmentListing
            setValue={setValue}
            getValues={getValues}
            control={control}
            handleChangeOtherNormal={handleChangeOtherNormal}
            handleFormData={handleFormData}
            handleFormDataOther={handleFormDataOther}
            values={values}
            valuesOther={valuesOther}
            selectValues={selectValues}
            register={register}
            error={error}
            clearErrors={clearErrors}
            trigger={trigger}
          />
        ) : values?.PackageItemType === "Seafood" ? (
          <SeaFoodListing
            handleChangeOtherNormal={handleChangeOtherNormal}
            setValue={setValue}
            getValues={getValues}
            control={control}
            handleFormData={handleFormData}
            handleFormDataOther={handleFormDataOther}
            valuesOther={valuesOther}
            values={values}
            selectValues={selectValues}
            register={register}
            error={error}
            clearErrors={clearErrors}
            trigger={trigger}
          />
        )
          // : values?.PackageItemType === "Pleasure Boat" ? (
          //   <PleasureBoatListing
          //     handleChangeOtherNormal={handleChangeOtherNormal}
          //     setValue={setValue}
          //     getValues={getValues}
          //     control={control}
          //     handleFormDataOther={handleFormDataOther}
          //     valuesOther={valuesOther}
          //     values={values}
          //     selectValues={selectValues}
          //     register={register}
          //     error={error}
          //     clearErrors={clearErrors}
          //   />
          // ) 
          : values?.PackageItemType === "Boat" ? (
            <>
              <p className="good_day_para">
                Please Advise Atleast One Of The Following
              </p>
              <div className="button-wrap">
                <input
                  className="hidden radio-label"
                  type="radio"
                  name="VesselType"
                  id="fishing-button"
                  value="Fishing"
                  checked={values.VesselType === "Fishing" ? "checked" : ""}
                  onClick={handleButtonInput}
                />
                <label className="button-label" for="fishing-button">
                  <h1>Fishing</h1>
                </label>
                <input
                  className="hidden radio-la`bel"
                  type="radio"
                  name="VesselType"
                  id="craft-button"
                  value="Pleasure Craft"
                  checked={values.VesselType === "Pleasure Craft" ? "checked" : ""}
                  onClick={handleButtonInput}
                />
                <label className="button-label" for="craft-button">
                  <h1>Pleasure Craft</h1>
                </label>
                <input
                  className="hidden radio-label"
                  type="radio"
                  name="VesselType"
                  id="other-button"
                  value="Other"
                  checked={values.VesselType === "Other" ? "checked" : ""}
                  onClick={handleButtonInput}
                />
                <label className="button-label" for="other-button">
                  <h1>Other</h1>
                </label>
              </div>
              {btnError && <p className="err-msg">Please select one of above</p>}
            </>
          ) : (
            <div className="step well ">
              <p className="good_day_para">Select the Assets to Include in Your Package (Complete Package/Enterprise/License Package)</p>
              <p>You may choose one or more assets from the options below. After entering the details for each asset, you will return to this screen to select additional assets if needed. Continue adding assets until your complete package/enterprise/license package is finalized</p>
              <form>
                <div className="button-wrap">
                  <input className="hidden radio-label" type="radio" name="PackageItemType" value="Boat" id="package1-button" checked={values.PackageItemType === 'Boat' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
                  <label className="button-label" for="package1-button">
                    <h1>Vessel</h1>
                  </label>
                  <input className="hidden radio-label" type="radio" name="PackageItemType" value="License" id="package2-button" checked={values.PackageItemType === 'License' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
                  <label className="button-label" for="package2-button">
                    <h1>License</h1>
                  </label>

                  <input className="hidden radio-label" type="radio" name="PackageItemType" value="Quota" id="package3-button" checked={values.PackageItemType === 'Quota' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
                  <label className="button-label" for="package3-button">
                    <h1>Quota</h1>
                  </label>
                  <input className="hidden radio-label" type="radio" name="PackageItemType" value="Equipment" id="package5-button" checked={values.PackageItemType === 'Equipment' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
                  <label className="button-label" for="package5-button">
                    <h1>Equipment</h1>
                  </label>
                  <input className="hidden radio-label" type="radio" name="PackageItemType" value="Seafood" id="package6-button" checked={values.PackageItemType === 'Seafood' ? 'checked' : ''} onClick={handleCustomButtonSelect} />
                  <label className="button-label" for="package6-button">
                    <h1>Seafood</h1>
                  </label>
                </div>
                {btnError && <p className='err-msg'>Please select one of above</p>}
              </form>
            </div>
          )}

    </>
  );
};

const EquipmentListing = ({
  handleFormData,
  values,
  selectValues,
  register,
  error,
  clearErrors,
  getValues,
  setValue,
  control,
}) => {
  return (
    <>
      <p className="good_day_para">
        Please fill the following details for Equipment/Gear
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              Category<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="EquipmentCategory"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("EquipmentCategory", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "EquipmentCategory",
                      },
                    });
                    clearErrors("EquipmentCategory");
                  }}
                  defaultValue={
                    selectValues?.brokerEquipmentCategory?.find(
                      (e) => e.id === getValues("EquipmentCategory")
                    )
                      ? {
                        value: selectValues?.brokerEquipmentCategory.find(
                          (e) => e.id === getValues("EquipmentCategory")
                        ).id,
                        label: selectValues?.brokerEquipmentCategory.find(
                          (e) => e.id === getValues("EquipmentCategory")
                        )?.name,
                      }
                      : {
                        value: getValues("EquipmentCategory"),
                        label: getValues("EquipmentCategory"),
                      }
                  }
                  options={selectValues?.brokerEquipmentCategory?.map(
                    (region, index) => {
                      return { value: region.id, label: region.name };
                    }
                  )}
                />
              )}
            />
            {error?.EquipmentCategory && (
              <span className="err-msg">
                {error?.EquipmentCategory?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Product Type<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="EquipmentProduct"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("EquipmentProduct", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "EquipmentProduct",
                      },
                    });
                    clearErrors("EquipmentProduct");
                  }}
                  defaultValue={
                    selectValues?.brokerEquipmentProduct?.find(
                      (e) => e.id === getValues("EquipmentProduct")
                    )
                      ? {
                        value: selectValues?.brokerEquipmentProduct.find(
                          (e) => e.id === getValues("EquipmentProduct")
                        ).id,
                        label: selectValues?.brokerEquipmentProduct.find(
                          (e) => e.id === getValues("EquipmentProduct")
                        )?.name,
                      }
                      : {
                        value: getValues("EquipmentProduct"),
                        label: getValues("EquipmentProduct"),
                      }
                  }
                  options={selectValues.brokerEquipmentProduct
                    .filter((e) => e.category === values?.EquipmentCategory)
                    .map((region, index) => {
                      return { value: region.id, label: region.name };
                    })}
                />
              )}
            />
            {error?.EquipmentProduct && (
              <span className="err-msg">
                {error?.EquipmentProduct?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Manufacturer<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="EquipmentManufacturer"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("EquipmentManufacturer", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "EquipmentManufacturer",
                      },
                    });
                    clearErrors("EquipmentManufacturer");
                  }}
                  defaultValue={
                    selectValues?.brokerEquipmentManufacturer?.find(
                      (e) => e.id === getValues("EquipmentManufacturer")
                    )
                      ? {
                        value: selectValues?.brokerEquipmentManufacturer.find(
                          (e) => e.id === getValues("EquipmentManufacturer")
                        ).id,
                        label: selectValues?.brokerEquipmentManufacturer.find(
                          (e) => e.id === getValues("EquipmentManufacturer")
                        )?.name,
                      }
                      : {
                        value: getValues("EquipmentManufacturer"),
                        label: getValues("EquipmentManufacturer"),
                      }
                  }
                  options={selectValues?.brokerEquipmentManufacturer?.map(
                    (manufacturer, index) => {
                      return {
                        value: manufacturer.id,
                        label: manufacturer.name,
                      };
                    }
                  )}
                />
              )}
            />
            {error?.EquipmentManufacturer && (
              <span className="err-msg">
                {error?.EquipmentManufacturer?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Province
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="EquipmentProvince"
              control={control}
              defaultValue=""
              // rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("EquipmentProvince", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "EquipmentProvince",
                      },
                    });
                    // clearErrors("EquipmentProvince");
                  }}
                  defaultValue={
                    selectValues?.states?.find(
                      (e) => e.id === getValues("EquipmentProvince")
                    )
                      ? {
                        value: selectValues?.states.find(
                          (e) => e.id === getValues("EquipmentProvince")
                        ).id,
                        label: selectValues?.states.find(
                          (e) => e.id === getValues("EquipmentProvince")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.states?.map((manufacturer, index) => {
                    return { value: manufacturer.id, label: manufacturer.name };
                  })}
                />
              )}
            />
            {/* {error?.EquipmentProvince && (
              <span className="err-msg">
                {error?.EquipmentProvince?.message}
              </span>
            )} */}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Min Price
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MinPriceWillingToPay"
              defaultValue={values?.MinPriceWillingToPay}
              className="popup_input"
              id="MinPriceWillingToPay"
              {...register("MinPriceWillingToPay", {
                // required: "This field is required.",
                valueAsNumber: true,
                // validate: {
                //   required: (value) => {
                //     if (parseFloat(value) <= 100) {
                //       return "Minimum price should not be less than or equal to $100";
                //     } else if (value >= getValues("MaxPriceWillingToPay")) {
                //       return "Minimum price should not be greater or equal to maximum price";
                //     }
                //   },
                // },
              })}
              onChange={(e) => {
                clearErrors("MinPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MinPriceWillingToPay && (
              <span className="err-msg">
                {error?.MinPriceWillingToPay?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Max Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MaxPriceWillingToPay"
              defaultValue={values?.MaxPriceWillingToPay}
              className="popup_input"
              id="ItemPrice"
              {...register("MaxPriceWillingToPay", {
                required: "This field is required.",
                validate: {
                  maxNumber: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Maximum price should not be less than or equal to $100";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MaxPriceWillingToPay");
                handleFormData(e);
              }}
            />
            <span className="err-msg">
              {error?.MaxPriceWillingToPay?.message}
            </span>
          </div>
        </div>
      </form>
    </>
  );
};

const SeaFoodListing = ({
  handleFormData,
  values,
  selectValues,
  register,
  error,
  clearErrors,
  getValues,
  setValue,
  control,
}) => {
  const [countries, setCountries] = useState([]); // state for store countries data

  // handle for call countries api
  function handleCountries(evt) {
    var data = new FormData();
    data.append("state_id", evt);
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_KEY + "get-countries",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setCountries(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <p className="good_day_para">
        Please fill the following details for Seafood
      </p>
      <form>
        <div className="row">
          {/* <div className="col-md-12">
            <span className="popup_input_label">
              Region<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodRegion"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("SeaFoodRegion", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "SeaFoodRegion",
                      },
                    });
                    clearErrors("SeaFoodRegion");
                  }}
                  defaultValue={
                    selectValues?.brokerRegion.find(
                      (e) => e.id === getValues("SeaFoodRegion")
                    )
                      ? {
                        value: selectValues?.brokerRegion.find(
                          (e) => e.id === getValues("SeaFoodRegion")
                        ).id,
                        label: selectValues?.brokerRegion.find(
                          (e) => e.id === getValues("SeaFoodRegion")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.brokerRegion?.map(
                    (manufacturer, index) => {
                      return {
                        value: manufacturer.id,
                        label: manufacturer.name,
                      };
                    }
                  )}
                />
              )}
            />
            {error?.SeaFoodRegion && (
              <span className="err-msg">{error?.SeaFoodRegion?.message}</span>
            )}
          </div> */}
          <div className="col-md-12">
            <span className="popup_input_label">
              General Species<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodSpecies"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("SeaFoodSpecies", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "SeaFoodSpecies",
                      },
                    });
                    clearErrors("SeaFoodSpecies");
                  }}
                  defaultValue={
                    selectValues?.brokerGeneralSpecies.find(
                      (e) => e.id === getValues("SeaFoodSpecies")
                    )
                      ? {
                        value: selectValues?.brokerGeneralSpecies.find(
                          (e) => e.id === getValues("SeaFoodSpecies")
                        ).id,
                        label: selectValues?.brokerGeneralSpecies.find(
                          (e) => e.id === getValues("SeaFoodSpecies")
                        )?.name,
                      }
                      : {
                        value: getValues("SeaFoodSpecies"),
                        label: getValues("SeaFoodSpecies"),
                      }
                  }
                  options={selectValues?.brokerGeneralSpecies?.map(
                    (manufacturer, index) => {
                      return {
                        value: manufacturer.id,
                        label: manufacturer.name,
                      };
                    }
                  )}
                />
              )}
            />
            {error?.SeaFoodSpecies && (
              <span className="err-msg">{error?.SeaFoodSpecies?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Size
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodSize"
              control={control}
              defaultValue=""
              // rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("SeaFoodSize", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "SeaFoodSize",
                      },
                    });
                    clearErrors("SeaFoodSize");
                  }}
                  defaultValue={
                    selectValues?.brokerGeneralSpeciesSize.find(
                      (e) => e.id === getValues("SeaFoodSize")
                    )
                      ? {
                        value: selectValues?.brokerGeneralSpeciesSize.find(
                          (e) => e.id === getValues("SeaFoodSize")
                        ).id,
                        label: selectValues?.brokerGeneralSpeciesSize.find(
                          (e) => e.id === getValues("SeaFoodSize")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.brokerGeneralSpeciesSize?.map(
                    (manufacturer, index) => {
                      return {
                        value: manufacturer.id,
                        label: manufacturer.name,
                      };
                    }
                  )}
                />
              )}
            />
            {error?.SeaFoodSize && (
              <span className="err-msg">{error?.SeaFoodSize?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Min Quantity
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="SeaFoodMinQuantity"
              defaultValue={values.SeaFoodMinQuantity}
              className="popup_input"
              id="SeaFoodMinQuantity"
              {...register("SeaFoodMinQuantity")}
              onChange={(e) => {
                clearErrors("SeaFoodMinQuantity");
                handleFormData(e);
              }}
            />
            {error?.SeaFoodMinQuantity && (
              <span className="err-msg">
                {error?.SeaFoodMinQuantity?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Max Quantity<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="SeaFoodMaxQuantity"
              defaultValue={values.SeaFoodMaxQuantity}
              className="popup_input"
              id="SeaFoodMaxQuantity"
              {...register("SeaFoodMaxQuantity", {
                required: "This field is required",
                validate: {
                  required: (value) => {
                    if (parseFloat(value) <= 0) {
                      return "Max quantity should be numeric and greater than zero.";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("SeaFoodMaxQuantity");
                handleFormData(e);
              }}
            />
            {error?.SeaFoodMaxQuantity && (
              <span className="err-msg">
                {error?.SeaFoodMaxQuantity?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Province
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodProvince"
              control={control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("SeaFoodProvince", selectedOption?.value);
                    handleCountries(selectedOption.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "SeaFoodProvince",
                      },
                    });
                    clearErrors("SeaFoodProvince");
                  }}
                  defaultValue={
                    selectValues?.states.find(
                      (e) => e.id === getValues("SeaFoodProvince")
                    )
                      ? {
                        value: selectValues?.states.find(
                          (e) => e.id === getValues("SeaFoodProvince")
                        ).id,
                        label: selectValues?.states.find(
                          (e) => e.id === getValues("SeaFoodProvince")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.states?.map((state, index) => {
                    return { value: state.id, label: state.name };
                  })}
                />
              )}
            />
            {error?.SeaFoodProvince && (
              <span className="err-msg">{error?.SeaFoodProvince?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              County
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodCountry"
              control={control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("SeaFoodCountry", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "SeaFoodCountry",
                      },
                    });
                    clearErrors("SeaFoodCountry");
                  }}
                  defaultValue={
                    countries.find((e) => e.id === getValues("SeaFoodCountry"))
                      ? {
                        value: countries.find(
                          (e) => e.id === getValues("SeaFoodCountry")
                        ).id,
                        label: countries.find(
                          (e) => e.id === getValues("SeaFoodCountry")
                        )?.name,
                      }
                      : ""
                  }
                  options={countries?.map((state, index) => {
                    return { value: state.id, label: state.name };
                  })}
                />
              )}
            />
            {error?.SeaFoodCountry && (
              <span className="err-msg">{error?.SeaFoodCountry?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Min Price
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MinPriceWillingToPay"
              defaultValue={values.MinPriceWillingToPay}
              className="popup_input"
              id="MinPriceWillingToPay"
              {...register("MinPriceWillingToPay", {
                valueAsNumber: true
              })}
              onChange={(e) => {
                clearErrors("MinPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MinPriceWillingToPay && (
              <span className="err-msg">
                {error?.MinPriceWillingToPay?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Max Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MaxPriceWillingToPay"
              defaultValue={values.MaxPriceWillingToPay}
              className="popup_input"
              id="ItemPrice"
              {...register("MaxPriceWillingToPay", {
                required: "This field is required.",
                validate: {
                  maxNumber: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Maximum price should not be less than or equal to $100";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MaxPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error?.MaxPriceWillingToPay && (
              <span className="err-msg">
                {error?.MaxPriceWillingToPay?.message}
              </span>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const Step3 = ({
  values,
  handleFormData,
  handleCustomButtonSelect,
  handleChangeOtherNormal,
  handleButtonInput,
  handleFormDataOther,
  valuesOther,
  btnError,
  error,
  register,
  selectValues,
  handleLicenseArea,
  clearErrors,
  getValues,
  trigger,
  setValue,
  control,
}) => {

  console.log('itemTYpe', values.ItemType)
  return (
    <>
      {(values.ItemType === "License" || values.ItemType === "Quota") ? (
        <LicenseListing
          handleFormData={handleFormData}
          values={values}
          selectValues={selectValues}
          register={register}
          getValues={getValues}
          trigger={trigger}
          error={error}
          handleLicenseArea={handleLicenseArea}
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
        />
      ) : values.ItemType === "Package" ? (
        <PackageListing
          handleChangeOtherNormal={handleChangeOtherNormal}
          handleCustomButtonSelect={handleCustomButtonSelect}
          control={control}
          handleFormDataOther={handleFormDataOther}
          valuesOther={valuesOther}
          values={values}
          selectValues={selectValues}
          register={register}
          error={error}
          btnError={btnError}
          clearErrors={clearErrors}
          setValue={setValue}
          getValues={getValues}
          handleFormData={handleFormData}
          handleButtonInput={handleButtonInput}
          trigger={trigger}
        />
      ) : values.ItemType === "Equipment" ? (
        <EquipmentListing
          handleFormData={handleFormData}
          values={values}
          getValues={getValues}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
        />
      ) : values.ItemType === "Seafood" ? (
        <SeaFoodListing
          handleFormData={handleFormData}
          values={values}
          getValues={getValues}
          trigger={trigger}
          selectValues={selectValues}
          register={register}
          error={error}
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
        />
      ) : values.ItemType === "Boat" ? (
        <>
          <p className="good_day_para">
            Please Advise Atleast One Of The Following
          </p>
          <div className="button-wrap">
            <input
              className="hidden radio-label"
              type="radio"
              name="VesselType"
              id="fishing-button"
              value="Fishing"
              checked={values.VesselType === "Fishing" ? "checked" : ""}
              onClick={handleButtonInput}
            />
            <label className="button-label" for="fishing-button">
              <h1>Fishing</h1>
            </label>
            <input
              className="hidden radio-la`bel"
              type="radio"
              name="VesselType"
              id="craft-button"
              value="Pleasure Craft"
              checked={values.VesselType === "Pleasure Craft" ? "checked" : ""}
              onClick={handleButtonInput}
            />
            <label className="button-label" for="craft-button">
              <h1>Pleasure Craft</h1>
            </label>
            <input
              className="hidden radio-label"
              type="radio"
              name="VesselType"
              id="other-button"
              value="Other"
              checked={values.VesselType === "Other" ? "checked" : ""}
              onClick={handleButtonInput}
            />
            <label className="button-label" for="other-button">
              <h1>Other</h1>
            </label>
          </div>
          {btnError && <p className="err-msg">Please select one of above</p>}
        </>
      ) : (
        <div className="step well ">
          <div className="midBoxBut">
            <h3 style={{ color: "red" }}>Please select an option</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default Step3;
