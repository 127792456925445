import React from "react";



const Step2 = ({ userName, handleFormData, values, error }) => {
    return (
        <>
            <p className="good_day_para">Select An Item That You Want To Buy/Lease</p>
            <div className="button-wrap">
                <input className="hidden radio-label" type="radio" name="ItemType" value="Boat" id="buy-lease1-button" checked={values.ItemType === 'Boat' ? 'checked' : ''} onClick={handleFormData} />
                <label className="button-label" for="buy-lease1-button">
                    <h1>Vessel</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="ItemType" value="License" id="buy-lease2-button" checked={values.ItemType === 'License' ? 'checked' : ''} onClick={handleFormData} />
                <label className="button-label" for="buy-lease2-button">
                    <h1>License</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="ItemType" value="Package" id="buy-lease6-button" checked={values.ItemType === 'Package' ? 'checked' : ''} onClick={handleFormData} />
                <label className="button-label" for="buy-lease6-button">
                    <h1>Complete Package</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="ItemType" value="Quota" id="buy-lease5-button" checked={values.ItemType === 'Quota' ? 'checked' : ''} onClick={handleFormData} />
                <label className="button-label" for="buy-lease5-button">
                    <h1>Quota</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="ItemType" value="Equipment" id="buy-lease3-button" checked={values.ItemType === 'Equipment' ? 'checked' : ''} onClick={handleFormData} />
                <label className="button-label" for="buy-lease3-button">
                    <h1>Equipment</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="ItemType" value="Seafood" id="buy-lease4-button" checked={values.ItemType === 'Seafood' ? 'checked' : ''} onClick={handleFormData} />
                <label className="button-label" for="buy-lease4-button">
                    <h1>Sea Food</h1>
                </label>
            </div>
            {/* <button className={`customBluebut btn btn-primary buyerrequest ${(values.ItemType == 'Other') ? "active" : ""}`} name="ItemType" value="Other" onClick={handleFormData}>Other</button> */}
            {error && <p className='err-msg'>Please select one of above</p>}
        </>
    );
}

export default Step2;
