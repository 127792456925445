import React, { useState } from 'react'
import { JobModal } from '../Modals/JobModal'
import { useForm } from 'react-hook-form';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const AddJob = ({ submitting, onSubmit, toggle, isModalOpen, handleCloseModal, jobDetail, userData }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const {
        register,
        trigger,
        setError,
        handleSubmit,
        formState: { errors },
        clearErrors,
        reset,
    } = useForm(); // using react useform hook values 

    return (
        <>
            <button className="save_changes_btn mb-0 py-2" data-bs-toggle="modal" data-bs-target="#addnewjob">+ Add New Job</button>
            <div class="modal fade" id="addnewjob" tabindex="-1" aria-labelledby="addnewjobLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header  border-0">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div class="modal-body text-start">
                                <div className="ct_job_form">
                                    <h4 className="text-center">Add New Job</h4>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Post Type</label>
                                                <select
                                                    id="postingType"
                                                    className="form-control"
                                                    {...register('postingType', { required: 'This field is required' })}
                                                >
                                                    <option value="">Select Posting Type</option>
                                                    <option value="Marine Job Offered">Marine Job Offered</option>
                                                    <option value="Marine Job Wanted">Marine Job Wanted</option>
                                                    <option value="Fishing Job Offered">Fishing Job Offered</option>
                                                    <option value="Fishing Job Wanted">Fishing Job Wanted</option>
                                                </select>
                                                {errors?.postingType && (
                                                    <span className="err-msg">{errors?.postingType?.message}</span>
                                                )}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Title or Desired Job Title</label>
                                                <span
                                                    id="Tooltip1"
                                                    className="pl-3"
                                                    style={{ display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>

                                                <Tooltip
                                                    isOpen={tooltipOpen === 'Tooltip1'}
                                                    autohide={false}
                                                    placement="top"
                                                    target="Tooltip1"
                                                    toggle={() => toggle('Tooltip1')}
                                                    popperClassName="product-tooltip"
                                                >
                                                    Enter the specific job title. If you are posting a job offer, use the official job title. If you are seeking a job, enter the title of the job you are looking for.                          <br />
                                                </Tooltip>
                                                <input type="text" className="form-control" placeholder="e.g., Fishing Captain, Deckhand, Marine Engineer" {...register('jobTitle', { required: 'This field is required' })} />
                                                {errors?.jobTitle && (
                                                    <span className="err-msg">{errors?.jobTitle?.message}</span>
                                                )}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Type</label>
                                                <span
                                                    id="Tooltip2"
                                                    className="pl-3"
                                                    style={{ display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                                <Tooltip
                                                    isOpen={tooltipOpen === 'Tooltip2'}
                                                    autohide={false}
                                                    placement="top"
                                                    target="Tooltip2"
                                                    toggle={() => toggle('Tooltip2')}
                                                    popperClassName="product-tooltip"
                                                >
                                                    Select the nature of the job. 'Seasonal (Fishing Season)' applies to jobs available only during specific fishing seasons, 'Trip-Based' refers to positions hired per fishing trip, and 'On-Call' is for jobs where workers are needed on short notice. If none of these apply, select 'Other' and specify.<br />
                                                </Tooltip>
                                                <select
                                                    id="jobType"
                                                    className="form-control"
                                                    {...register('jobType', { required: 'This field is required' })}
                                                >
                                                    <option value="">Select Job Type</option>
                                                    <option value="Full-Time">Full-Time</option>
                                                    <option value="Part-Time">Part-Time</option>
                                                    <option value="Seasonal (Fishing Season)">Seasonal (Fishing Season)</option>
                                                    <option value="Trip-Based">Trip-Based</option>
                                                    <option value="Contract (Short-Term)">Contract (Short-Term)</option>
                                                    <option value="Contract (Long-Term)">Contract (Long-Term)</option>
                                                    <option value="On-Call">On-Call</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors?.jobType && (
                                                    <span className="err-msg">{errors?.jobType?.message}</span>
                                                )}
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Location or Preferred Location</label>
                                                <span
                                                    id="Tooltip3"
                                                    className="pl-3"
                                                    style={{ display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                                <Tooltip
                                                    isOpen={tooltipOpen === 'Tooltip3'}
                                                    autohide={false}
                                                    placement="top"
                                                    target="Tooltip3"
                                                    toggle={() => toggle('Tooltip3')}
                                                    popperClassName="product-tooltip"
                                                >
                                                    Specify the location of the job or your preferred work location. Include details like city, state, or offshore if relevant.<br />
                                                </Tooltip>
                                                <input type="text" className="form-control" placeholder="e.g., Halifax, NS, Offshore Newfoundland" {...register('jobLocation', { required: 'This field is required' })} />
                                                {errors?.jobLocation && (
                                                    <span className="err-msg">{errors?.jobLocation?.message}</span>
                                                )}
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Duration</label>
                                                <span
                                                    id="Tooltip4"
                                                    className="pl-3"
                                                    style={{ display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                                <Tooltip
                                                    isOpen={tooltipOpen === 'Tooltip4'}
                                                    autohide={false}
                                                    placement="top"
                                                    target="Tooltip4"
                                                    toggle={() => toggle('Tooltip4')}
                                                    popperClassName="product-tooltip"
                                                >
                                                    Select the expected duration of the job. If it's a specific short-term job, choose the appropriate duration. If the job length is variable or not listed, select 'Other' and specify.'
                                                    Validation: This is a required field.<br />
                                                </Tooltip>
                                                <select
                                                    id="jobDuration"
                                                    className="form-control"
                                                    {...register('jobDuration', { required: 'This field is required' })}
                                                >
                                                    <option value="1 Day">1 Day</option>
                                                    <option value="2-3 Days">2-3 Days</option>
                                                    <option value="1 Week">1 Week</option>
                                                    <option value="2-3 Weeks">2-3 Weeks</option>
                                                    <option value="1 Month">1 Month</option>
                                                    <option value="Ongoing">Ongoing</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors?.jobDuration && (
                                                    <span className="err-msg">{errors?.jobDuration?.message}</span>
                                                )}
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Description or Experience Summary</label>
                                                <span
                                                    id="Tooltip5"
                                                    className="pl-3"
                                                    style={{ display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                                <Tooltip
                                                    isOpen={tooltipOpen === 'Tooltip5'}
                                                    autohide={false}
                                                    placement="top"
                                                    target="Tooltip5"
                                                    toggle={() => toggle('Tooltip5')}
                                                    popperClassName="product-tooltip"
                                                >
                                                    For job offers, describe the role, including duties, responsibilities, and qualifications needed. If you are seeking a job, summarize your relevant experience and skills.<br />
                                                </Tooltip>
                                                <textarea
                                                    id="jobDescription"
                                                    {...register('jobDescription', {
                                                        required: 'This is a required field',
                                                    })}
                                                    rows="4"
                                                    cols="30"
                                                    placeholder="e.g., 5+ years experience in commercial fishing, responsible for navigation and crew management..."
                                                />
                                                {errors?.jobDescription && (
                                                    <span className="err-msg">{errors?.jobDescription?.message}</span>
                                                )}
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Salary/Wages or Desired Salary/Wages</label>
                                                <span
                                                    id="Tooltip6"
                                                    className="pl-3"
                                                    style={{ display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                                <Tooltip
                                                    isOpen={tooltipOpen === 'Tooltip6'}
                                                    autohide={false}
                                                    placement="top"
                                                    target="Tooltip6"
                                                    toggle={() => toggle('Tooltip6')}
                                                    popperClassName="product-tooltip"
                                                >
                                                    If you are offering a job, provide the salary or wage range. If you are looking for a job, enter your expected salary range.<br />
                                                </Tooltip>
                                                <input type="text" className="form-control" placeholder="e.g., $50,000 - $70,000 per year, $25 per hour, or based on catch share" {...register('jobSalary')} />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Certifications</label>
                                                <span
                                                    id="Tooltip7"
                                                    className="pl-3"
                                                    style={{ display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                                <Tooltip
                                                    isOpen={tooltipOpen === 'Tooltip7'}
                                                    autohide={false}
                                                    placement="top"
                                                    target="Tooltip7"
                                                    toggle={() => toggle('Tooltip7')}
                                                    popperClassName="product-tooltip"
                                                >
                                                    List any certifications required for the job you are offering or certifications you hold if you are seeking a job. Separate multiple certifications with commas.<br />
                                                </Tooltip>
                                                <input type="text" className="form-control" placeholder="e.g., Marine First Aid, Fishing Master Class IV, STCW" {...register('certifications')} />
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Berthage of Vessel or Work Location</label>
                                                <span
                                                    id="Tooltip8"
                                                    className="pl-3"
                                                    style={{ display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                                <Tooltip
                                                    isOpen={tooltipOpen === 'Tooltip8'}
                                                    autohide={false}
                                                    placement="top"
                                                    target="Tooltip8"
                                                    toggle={() => toggle('Tooltip8')}
                                                    popperClassName="product-tooltip"
                                                >
                                                    Specify the exact berthage of the vessel or the specific work location. Include details like the name of the port, dock, or offshore location.<br />
                                                </Tooltip>
                                                <input type="text" className="form-control" placeholder="e.g., Pier 21, Halifax Harbour" {...register('workLocation', { required: 'This is a required field' })} />
                                                {errors?.workLocation && (
                                                    <span className="err-msg">{errors?.workLocation?.message}</span>
                                                )}
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="ct_fw_600 ct_fs_16">Additional Information</label>
                                                <textarea
                                                    id="additionalInformation"
                                                    {...register('additionalInformation')}
                                                    rows="4"
                                                    cols="30"
                                                    placeholder="e.g., Flexible working hours, on-board accommodations available, opportunity for advancement..."
                                                />
                                            </div>
                                            <div class="row form-group mb-3">

                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="ct_fw_600 ct_fs_16">Name</label>
                                                    <input id="userName" type="text" className="form-control" placeholder="Enter your name" {...register('userName', { required: 'This field is required' })} />

                                                    {errors?.userName && (
                                                        <span className="err-msg">{errors?.userName?.message}</span>
                                                    )}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="ct_fw_600 ct_fs_16">Email Id</label>
                                                    <input type="text" className="form-control" placeholder="Enter your email" {...register('userEmail', { required: 'This field is required', pattern: { value: /\S+@\S+\.\S+/, message: "Entered value does not match email format" }, })} />

                                                    {errors?.userEmail && (
                                                        <span className="err-msg">{errors?.userEmail?.message}</span>
                                                    )}
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="ct_fw_600 ct_fs_16">Phone Number</label>
                                                    <input type="text" className="form-control" placeholder="Enter your phone number" {...register('userPhone', { required: 'This field is required', valueAsNumber: true })} />

                                                    {errors?.userPhone && (
                                                        <span className="err-msg">{errors?.userPhone?.message}</span>
                                                    )}
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="ct_fw_600 ct_fs_16">Disclaimer</label>
                                                    <p>
                                                        By submitting this job, you consent to the display of your job details and contact information (phone number or email) to logged-in users. Novi Marine Brokers and its staff are not liable for any communications, transactions, or claims arising from this posting, and you agree to hold them harmless. You are responsible for vetting contacts and marking the job as filled. This agreement is governed by the laws of Canada, the USA, and other relevant jurisdictions.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer border-0">
                                <button type="subnit" disabled={submitting ? true : false} class="save_changes_btn">{submitting ? 'Submitting' : 'Save Changes'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <JobModal isOpen={isModalOpen} onClose={() => handleCloseModal()} jobDetail={jobDetail} userData={userData?.data?.user} />
        </>
    )
}

export default AddJob