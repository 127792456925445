import MembershipContent from "../../screens/MembershipContent";
import {
    CLOSEICON
} from "../../constants/imagepath";
import React, { useState, useEffect, lazy } from "react";
import Skeleton from "react-loading-skeleton";
import { updateJobStatus, viewEmailPhone } from "../../api_services/api_calls/jobsApi";
import { toast } from "react-toastify";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const JobModal = ({ isOpen, onClose, jobDetail, userData, ...props }) => {

    console.log('visiblity', isOpen);
    console.log('JobModal - jobDetailss', jobDetail);
    console.log('JobModal - userData', userData);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [MainNumber, SetMainNumber] = useState(false);
    const [emailDisplay, setEmailDisplay] = useState(false);
    const [jobStatus, setJobStatus] = useState('');
    const [isOwner, setOwner] = useState(false);
    const [loading, setLoading] = useState(false);
    const closePackageModal = () => {
        onClose(!isOpen);
    };

    const handleMainNumber = () => {
        SetMainNumber(true)
        let data = {
            "job_post_id": jobDetail?.id,
            "phone_or_email": "1"
        };
        viewEmailPhone(data);
    }

    const handleEmail = () => {
        setEmailDisplay(true)
        let data = {
            "job_post_id": jobDetail?.id,
            "phone_or_email": "2"
        };
        viewEmailPhone(data);
    }

    const updateStatus = () => {
        setLoading(true);
        let obj = { id: jobDetail.id, job_status: jobStatus }
        updateJobStatus(obj).then(function (resp) {
            //setHasMoreItems(false);
            setLoading(false);
            if (resp.data.status === true) {
                toast.success(resp.data.message);
            } else {
                toast.error(resp.data.message);
            }
            // close the modal
            closePackageModal()
        }).catch(function (error) {
            toast.error("Something went wrong");
            // close the modal
            closePackageModal()
        });


    }

    const toggle = (tooltipId) => {
        setTooltipOpen(tooltipOpen === tooltipId ? null : tooltipId);
    };

    // useEffect(() => {
    //     // Conditional effect
    //     console.log('job_user', jobDetail.user_id);
    //     console.log('user_ids', userData)
    //     alert('job_user', jobDetail.user_id);
    //     alert('user_ids', userData)
    //     if (jobDetail.user_id === userData.id) {
    //         // Perform your action here
    //         console.log('User IDs match, performing action...');
    //         setOwner(true);
    //         setJobStatus(jobDetail.job_status)
    //         // Example action: fetch data, update state, etc.
    //     }
    //     else {
    //         setOwner(false);
    //     }
    // }, [jobDetail, userData]); // Dependencies array

    console.log('owner', isOwner)
    return (
        <>
            {isOpen ? (
                <>
                    <div id="productPopup" className="overlay_2 text-left">

                        <div className="popup1">
                            <div className="row">
                                <div className="popup-header custom-close-btn px-5 py-3">
                                    <a className="close" href="javascript:void(0)">
                                        <img
                                            alt="closeIcon"
                                            src={CLOSEICON}
                                            onClick={closePackageModal}
                                        />
                                    </a>
                                </div>
                                <div className="row p-4">
                                    {
                                        (jobDetail.length === 0)
                                            ?
                                            <Skeleton count={7.7} />
                                            :
                                            <div className="col-md-12">

                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Post Type</label><div className="col-sm-8"><span className=" ">{jobDetail.posting_type}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Job Title</label><div className="col-sm-8"><span className=" ">{jobDetail.title}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Job Type</label><div className="col-sm-8"><span className=" ">{jobDetail.job_type}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Preferred Location</label><div className="col-sm-8"><span className=" ">{jobDetail.preferred_location}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Job Duration</label><div className="col-sm-8"><span className=" ">{jobDetail.job_duration}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Job Description</label><div className="col-sm-8"><span className=" ">{jobDetail.description}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Salary/ Wages</label><div className="col-sm-8"><span className=" ">{jobDetail.salary}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Certifications</label><div className="col-sm-8"><span className=" ">{jobDetail.certifications}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Work Location</label><div className="col-sm-8"><span className=" ">{jobDetail.work_location}</span></div></div>
                                                <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Additional Information</label><div className="col-sm-8"><span className=" ">{jobDetail.additional_information}</span></div></div>



                                                {jobDetail.job_status != 2 ? <>
                                                    <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Email Id</label><div className="col-sm-8">
                                                        <span className={emailDisplay ? '  d-none  ' : 'd-flex  align-items-center ct_px_res_8 m-0'} onClick={handleEmail}><i className="fa-solid fa-envelope" ></i></span>
                                                        <div className={emailDisplay ? 'd-block' : 'd-none'}>{jobDetail.email}</div>
                                                    </div></div>
                                                    <div className="row form-group mb-3"><label for="" className="col-sm-4 t_fw_600 ct_fs_16">Phone Number</label><div className="col-sm-8">
                                                        <button className={MainNumber ? '  d-none  ' : 'd-flex  align-items-center ct_px_res_8 m-0'} onClick={handleMainNumber}><i className="fa-solid fa-phone" ></i></button>
                                                        <div className={MainNumber ? 'd-block' : 'd-none'}> {jobDetail.phone_no}</div>
                                                    </div>
                                                    </div>
                                                </> : ""}

                                                {
                                                    (jobDetail?.user_id === userData?.id) && jobDetail.job_status != 2 ?
                                                        <>
                                                            <div className="row form-group mb-3">
                                                                <label for="jobStatus" className="col-sm-4 t_fw_600 ct_fs_16"> Job Status</label>
                                                                <div className="col-sm-8">
                                                                    <select id="jobStatus" className="form-select" value={jobStatus} onChange={(e) => setJobStatus(e.target.value)}>
                                                                        <option value="1">Running</option>
                                                                        <option value="2">Complete</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <button disabled={loading ? true : false} onClick={updateStatus}>{loading ? 'Updating...' : 'Update'}</button>
                                                            </div>
                                                        </> :
                                                        <div className="row form-group mb-3">
                                                            <label for="jobStatus" className="col-sm-4 t_fw_600 ct_fs_16"> Job Status</label>
                                                            <div className="col-sm-8">
                                                                {
                                                                    jobDetail.job_status == 1 ?
                                                                        <span className="badge badge-success p-2">Open</span> :
                                                                        <span className="badge badge-warning p-2">Filled</span>
                                                                }
                                                            </div>
                                                        </div>
                                                }


                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
                :
                ''}
        </>
    );
}
