import React, { useEffect } from 'react'

const BotPressChatbot = ({ show }) => {
  useEffect(() => {

    if (show) {
      const script = document.createElement('script')
      script.src = 'https://cdn.botpress.cloud/webchat/v2.2/inject.js'
      script.async = true
      document.body.appendChild(script)

      script.onload = () => {
        window.botpress.init({
          "botId": "2d9d16ac-235f-406d-a15f-ffbab5eb1bab",
          "configuration": {
            "composerPlaceholder": "",
            "botName": "Adrian",
            "botAvatar": "https://stagingnovimarinebrokers.com/img/Image.jpg",
            "botDescription": "Your personal marine assistant, here to help you with all your marine-related questions and needs.",
            "website": {
              "title": "https://novimarinebrokers.com",
              "link": "https://novimarinebrokers.com"
            },
            "email": {
              "title": "dane@novimarinebrokers.com",
              "link": "dane@novimarinebrokers.com"
            },
            "phone": {
              "title": "+1 (902) 742-7207",
              "link": "+1 (902) 742-7207"
            },
            "termsOfService": {
              "title": "https://novimarinebrokers.com/terms",
              "link": "https://novimarinebrokers.com/terms"
            },
            "privacyPolicy": {
              "title": "https://novimarinebrokers.com/privacy",
              "link": "https://novimarinebrokers.com/privacy"
            },
            "color": "#2563eb",
            "variant": "solid",
            "themeMode": "light",
            "fontFamily": "inter",
            "radius": 2,
            "showPoweredBy": false,
            "additionalStylesheetUrl": "https://files.bpcontent.cloud/2025/03/04/14/20250304144439-V9IAZ1RX.css"
          },
          "clientId": "5b12491e-485f-47a1-9d39-e199f6109214"
        });
      };

      document.body.appendChild(script);

      // Cleanup function to remove the script when component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }

  }, [show])

  return <div id="webchat" />
}

export default BotPressChatbot